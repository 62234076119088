
















import { Component, Vue } from 'vue-property-decorator';
import CanzoneDataService from "../services/CanzoneDataService";
import FormCanzone from './FormCanzone.vue';

@Component({
    components: {
        FormCanzone
    }
})

export default class ModificaCanzone extends Vue {
    private currentCanzone: any = null;

    // Initial data can be declared as instance properties
    pageTitle: string = 'Modifica Canzone';

    getCanzone(id: string) {
        CanzoneDataService.get(id)
            .then((response) => {
                this.currentCanzone = response.data;
            })
            .catch((e) => {
                console.log(e);
            });
    }

    mounted() {
        this.getCanzone(this.$route.params.id);
    }
}
